import React from "react"
import { Link } from "gatsby"
import Styled from "./styled"

const AnimatedLink = props => {
  const isInternalLink = props.isInternalLink

  return (
    <Styled.Link>
      {isInternalLink ? (
        <Link
          to={props.target}
          onMouseOver={props.onMouseOverEvent}
          onMouseOut={props.onMouseOutEvent}
        >
          {props.linkText}
        </Link>
      ) : (
        <a
          href={props.target}
          target="_blank"
          rel="noopener noreferrer"
          onMouseOver={props.onMouseOverEvent}
          onMouseOut={props.onMouseOutEvent}
          onClick={props.onClickAction}
        >
          {props.linkText}
        </a>
      )}
    </Styled.Link>
  )
}

export default AnimatedLink
