import styled from "styled-components"
import { motion } from "framer-motion"
import themeSettings from "../../styles/theme"

const Navigation = styled(motion.nav)`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100vw;
  max-width: 100%;
  height: ${themeSettings.navHeight}px;
  padding: 0 ${themeSettings.margins.page};

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${themeSettings.bodyBackgroundColor};
  background: rgb(255, 255, 255);
  background: linear-gradient(#f3f3f3, rgba(255, 255, 255, 0));

  z-index: ${themeSettings.zIndexes.navigation};

  @media screen and (max-width: 1024px) {
    /* background-color: ${props =>
      props.isActive ? "transparent" : themeSettings.bodyBackgroundColor}; */
    ${props =>
      props.isActive
        ? `
      span {
        &:first-child {
          opacity: 0;
          pointer-events: none !important;
        }
      }
    `
        : `
      span {
        &:first-child {
          opacity: 1;
          pointer-events: all;
        }
      }
    `}
  }

  @media screen and (max-width: 414px) {
    height: ${themeSettings.mobileNavHeight}px;
    padding: 0 ${themeSettings.mobileMargins.page};
  }

  & > span {
    /* Brand */
    &:nth-child(1) {
      & > a {
        white-space: pre;
      }
    }

    /* Center "Studio Fonts" Link horizontally */
    &:nth-child(2) {
      position: absolute;
      left: calc(50% - 70px);

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    /* Hides "Menu" on mobile */
    &:nth-child(3) {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
`

const NavigationContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: auto;
  cursor: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transform: ${props =>
    props.isActive ? "translate3d(0,0,0)" : "translate3d(0,-101%,0)"};
  will-change: transform;
  transition: ${props =>
    props.isActive
      ? "all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1)"
      : "all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1)"};
  z-index: 2;
  background-color: #000;
  padding: 22px 0 20px ${themeSettings.margins.page};
  * {
    opacity: 1 !important;
    pointer-events: all !important;
  }

  @media screen and (max-width: 1024px) {
    padding: 18px 0 20px ${themeSettings.margins.page};
  }

  @media screen and (max-width: 414px) {
    padding: 18px 0 20px 12px;
  }

  & > span {
    display: block;
    font-size: calc(${themeSettings.fontSizes.medium} * 1.6);
    line-height: 1;
    font-style: normal;
    letter-spacing: 0.02em;
    margin-bottom: 20px;

    a {
      color: #fff;
    }

    @media screen and (max-width: 1440px) {
      font-size: calc(${themeSettings.fontSizes.medium} * 1.2);
      margin-bottom: 12px;
    }

    @media screen and (max-width: 1024px) {
      line-height: inherit;
      font-size: calc(${themeSettings.fontSizes.medium} * 1.2);
      margin-bottom: 2px;
    }

    @media screen and (max-width: 768px) {
      font-size: 28px;
      margin-bottom: 2px;
    }
  }
`

const NavigationIcon = styled.a`
  box-sizing: border-box;
  position: relative;
  display: ${props => (props.isActive ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  width: 26px;
  height: 26px;
  padding: 6px 0;
  cursor: pointer;
  transform: translateY(1px);
  z-index: 3;
  div {
    width: 100%;
    height: 2px;
    background-color: ${props =>
      props.isActive ? "#fff !important" : themeSettings.mainColor};
    transition: all 0.075s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-delay: 0.12s;
    &:nth-child(1) {
      transform: ${props =>
        props.isActive
          ? "translate3d(0,6px,0) rotate(45deg)"
          : "translate3d(0,0,0) rotate(0deg)"};
    }
    &:nth-child(2) {
      opacity: ${props => (props.isActive ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${props =>
        props.isActive
          ? "translate3d(0,-6px,0) rotate(-45deg)"
          : "translate3d(0,0,0) rotate(0deg)"};
    }
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    & div {
      background-color: ${themeSettings.mainColor};
    }
  }
`

export default {
  Navigation,
  NavigationContainer,
  NavigationIcon,
}
