import styled, { keyframes } from "styled-components"
import { motion } from "framer-motion"
import themeSettings from "../../styles/theme"

export const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  z-index: ${themeSettings.zIndexes.loading};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: #fff;
  opacity: ${ props => props.isActive ? 1 : 0 };
  transition: opacity 0.3s;
  pointer-events: none;
  /* @media screen and (max-width: 1024px) {
    font-size: 20px;
  } */
  @media screen and (max-width: 414px) {
    font-size: ${themeSettings.fontSizes.small};
  }
`

export const WrapperHorizontal = styled.div`
  box-sizing: border-box;
  position: relative;
  width: calc(100% - (${themeSettings.margins.page} * 2));
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`

export const WrapperHorizontalColumn = styled.div`
  position: relative;
  flex-basis: calc(50% - 2px);
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;
  /* &:first-child {
    background-color: lightgreen;
  }
  &:last-child {
    background-color: lightcoral;
  } */
`

const wobble = keyframes`
  0% { transform: skew(0); }
  20% { transform: skew(-10deg); }
  40% { transform: skew(10deg); }
  60% { transform: skew(-5deg); }
  80% { transform: skew(5deg); }
  90% { transform: skew(2deg); }
  100% { transform: skew(0); }
`

export const Percentage = styled(motion.p)`
  /* width = 54px */
  position: absolute;
  left: -20px;
  will-change: transform;
  transition: transform 0.2s ease;
  span {
    display: inline-block;
    &.TimerStopped {
      animation: 0.8s ${wobble} ease-out;
    }
  }
`

export const PercentSign = styled(motion.p)`
  /* width = 25px */
  position: absolute;
  right: -20px;
  will-change: transform;
  transition: transform 0.2s ease-in-out;
  span {
    display: inline-block;
    &.TimerStopped {
      animation: 0.8s ${wobble} ease-out;
    }
  }
`