import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Styled from "./styled"
import AnimatedLink from "../animated-link"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      prismicFooter {
        uid
        data {
          about_text {
            text
          }
          e_mail {
            text
          }
          phone {
            text
          }
          follow_links {
            link_target {
              text
            }
            link_title {
              text
            }
          }
        }
      }
    }
  `)

  const content = data.prismicFooter.data

  const allFollowLinks =
    content.follow_links &&
    content.follow_links.map((node, index) => {
      return (
        <AnimatedLink
          key={index}
          isInternalLink={false}
          target={node.link_target.text}
          linkText={node.link_title.text}
        />
      )
    })

  return (
    <Styled.Footer>
      <div>{content.about_text.text}</div>

      <div>
        <AnimatedLink
          isInternalLink={false}
          target={"mailto:" + content.e_mail.text}
          linkText={content.e_mail.text}
        />
        <AnimatedLink
          isInternalLink={false}
          target={"tel:" + content.phone.text}
          linkText={content.phone.text}
        />
        <AnimatedLink
          isInternalLink={true}
          target="/imprint"
          linkText="Imprint"
        />
      </div>

      <div>
        <span>Follow on:</span>
        {allFollowLinks}
      </div>
    </Styled.Footer>
  )
}

export default Footer
