import React, { useState } from "react"
import Styled from "./styled"
import AnimatedLink from "../animated-link"

const Navigation = (props) => {

  const [navigation, setNavigation] = useState(false)
  const [navigationPointerEvents, setNavigationPointerEvents] = useState(false)

  const openNavigation = event => {
    event.preventDefault()
    setNavigation(true)
    setTimeout(() => {
      setNavigationPointerEvents(true)
    }, 300)
  }

  const toggleNavigation = event => {
    event.preventDefault()
    setNavigation(!navigation)
    setNavigationPointerEvents(!navigationPointerEvents)
  }

  return (
    <>
      <Styled.Navigation
        isActive={ navigation }
        style={{ opacity: props.loaderIsActive ? 0 : 1 }}
      >
        <AnimatedLink
          isInternalLink={true}
          target="/"
          linkText="Studio  Fabio  Biesel"
        />
        <AnimatedLink
          isInternalLink={false}
          target="http://www.sfb-fonts.com/"
          linkText="Studio fonts →"
        />
        <AnimatedLink
          isInternalLink={false}
          target="#"
          linkText="Menu"
          onClickAction={ openNavigation }
        />
        <Styled.NavigationIcon
          isActive={ navigation }
          onClick={ toggleNavigation }
        >
          <div />
          <div />
          <div />
        </Styled.NavigationIcon>
        <Styled.NavigationContainer
          style={{
            pointerEvents: navigationPointerEvents ? "all" : "none",
          }}
          isActive={ navigation }
        >
          <AnimatedLink
            isInternalLink={true}
            target="about"
            linkText="About"
          />
          <AnimatedLink
            isInternalLink={true}
            target="/projects"
            linkText="Projects"
          />
          <AnimatedLink
            isInternalLink={false}
            target="http://www.sfb-fonts.com/"
            linkText="SFB Fonts"
          />
          <AnimatedLink
            isInternalLink={true}
            target="/studio"
            linkText="Studio"
          />
        </Styled.NavigationContainer>
      </Styled.Navigation>
    </>
  )
}

export default Navigation