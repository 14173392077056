import styled, { keyframes } from "styled-components"

const wobble = keyframes`
  0% { transform: skew(0) }
  20% { transform: skew(-10deg) }
  40% { transform: skew(10deg) }
  60% { transform: skew(-5deg) }
  80% { transform: skew(5deg) }
  90% { transform: skew(2deg) }
  100% { transform: skew(0) }
`

const Link = styled.span` 
  cursor: pointer;
  &:hover {
    animation: 0.8s ${wobble} ease-out;
  }
`

export default {
  Link
}