import React, { useState, useEffect } from "react"
import AnimatedLink from "../animated-link"
import * as Styled from "./styled"

const CookieBanner = () => {
  const [view, setView] = useState("initial") // 'initial' | 'customize'
  const [showCookieBanner, setShowCookieBanner] = useState(false)

  const windowGlobal = typeof window !== "undefined" && window

  const acceptCookies = () => {
    windowGlobal.localStorage.setItem("fb-cookies-accepted", "true")
    setShowCookieBanner(false)
    setTimeout(() => {
      windowGlobal.location.reload()
    }, 500)
  }

  const declineCookies = () => {
    windowGlobal.localStorage.setItem("fb-cookies-accepted", "false")
    setShowCookieBanner(false)
  }

  useEffect(() => {
    if (window.localStorage.getItem("fb-cookies-accepted")) {
      setShowCookieBanner(false)
    } else {
      setShowCookieBanner(true)
    }
  }, [])

  return (
    <Styled.Banner style={{ display: showCookieBanner ? "flex" : "none" }}>
      {view === "initial" ? (
        <>
          <h3>
            <span>
              We only use cookies to ensure that you get the best functional
              experience on our website. We don&rsquo;t use any kind of tracking
              or advertising cookies.{" "}
            </span>
            <AnimatedLink
              isInternalLink
              target="imprint"
              linkText="More Info."
            />
          </h3>
          <Styled.BannerActions>
            <AnimatedLink
              isInternalLink={false}
              linkText="Decline"
              onClickAction={declineCookies}
            />
            <AnimatedLink
              isInternalLink={false}
              linkText="Customize"
              onClickAction={() => setView("customized")}
            />
            <AnimatedLink
              isInternalLink={false}
              linkText="Accept"
              onClickAction={acceptCookies}
            />
          </Styled.BannerActions>
        </>
      ) : (
        <>
          <h3>
            We are using Vimeo.com for video hosting. To enable videos, please
            allow cookies.
          </h3>
          <Styled.BannerActions>
            <AnimatedLink
              isInternalLink={false}
              linkText="Disallow Vimeo"
              onClickAction={declineCookies}
            />
            <AnimatedLink
              isInternalLink={false}
              linkText="Allow Vimeo"
              onClickAction={acceptCookies}
            />
          </Styled.BannerActions>
        </>
      )}
    </Styled.Banner>
  )
}

export default CookieBanner
