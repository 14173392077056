import styled from "styled-components"
import themeSettings from "../../styles/theme"

const Footer = styled.footer`
  box-sizing: border-box;
  width: 100vw;
  max-width: 100%;

  padding: ${themeSettings.margins.page};

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 414px) {
    padding: ${themeSettings.margins.page} ${themeSettings.mobileMargins.page} ${themeSettings.mobileMargins.page} ${themeSettings.mobileMargins.page};
  }

  & > div {
    width: calc(100% / 3);
    height: 100%;

    font-size: 16px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
      width: 70%;
      &:not(:last-child) {
        margin-bottom: ${themeSettings.margins.medium};
      }
    }

    @media screen and (max-width: 968px) {
      width: 100%;
    }

    /* Holds Mail link and Phone No */
    &:nth-child(2) {
      align-items: center;
      @media screen and (max-width: 1024px) {
        align-items: flex-start;
      }
    }

    /* Holds "Follow" links */
    &:nth-child(3) {
      align-items: flex-end;
      @media screen and (max-width: 1024px) {
        display: block;
        text-transform: uppercase;
        align-items: flex-start;
        & span {
          display: inline-block;
          margin-right: 6px;
          &:nth-child(2) {
            & a {
              &::after {
                content: ","
              }
            }
          }
        }
      }
    }

  }
`

export default { Footer }