import React, { useState, useEffect, useRef } from "react"
import gsap from "gsap"
import * as Styled from "./styled"

const Loading = (props) => {

  const [timerStopped, setTimerStopped] = useState(false)
  const [windowHeight, setWindowHeight] = useState(0)
  const counterTimeline = gsap.timeline()
  const wrapperHorizontalRef = useRef(null)
  const counterHorizontalRef = useRef(null)
  const counterWrapperHorizontalRef = useRef(null)
  const percentageSignHorizontalRef = useRef(null)

  useEffect(() => {

    counterTimeline
      .to(counterHorizontalRef.current,
        {
          duration: 3,
          ease: 'circ.out',
          innerHTML: '100',
          roundProps: 'innerHTML'
        }, 2)
      .fromTo(counterWrapperHorizontalRef.current,
        {
          left: -20,
        },
        {
          duration: 3,
          ease: 'circ.out',
          left: 'initial',
          right: 0
        }, 2)
      .fromTo(percentageSignHorizontalRef.current,
        {
          right: -20,
        },
        {
          duration: 3,
          ease: 'circ.out',
          left: 0,
        }, 2)

      setTimeout(() => {
        setTimerStopped(true)
      }, 5800)

  }, [])

  useEffect(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  return (
    <Styled.Loading isActive={ props.loaderIsActive } style={{ height: `${windowHeight}px` }}>
      <Styled.WrapperHorizontal ref={ wrapperHorizontalRef } isActive={ props.loaderIsActive }>
        <Styled.WrapperHorizontalColumn>
          <Styled.Percentage ref={ counterWrapperHorizontalRef }>
            <span ref={ counterHorizontalRef } className={ timerStopped ? 'TimerStopped' : '' }>0</span>
          </Styled.Percentage>
        </Styled.WrapperHorizontalColumn>
        <Styled.WrapperHorizontalColumn>
          <Styled.PercentSign ref={ percentageSignHorizontalRef }>
            <span className={ timerStopped ? 'TimerStopped' : '' }>%</span>
          </Styled.PercentSign>
        </Styled.WrapperHorizontalColumn>
      </Styled.WrapperHorizontal>
    </Styled.Loading>
  )
}

export default Loading