import styled, { keyframes } from "styled-components"
import themeSettings from "../../styles/theme"

const wobble = keyframes`
  0% { transform: skew(0) }
  20% { transform: skew(-10deg) }
  40% { transform: skew(10deg) }
  60% { transform: skew(-5deg) }
  80% { transform: skew(5deg) }
  90% { transform: skew(2deg) }
  100% { transform: skew(0) }
`

export const Banner = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  padding: ${themeSettings.margins.page};
  background-color: ${themeSettings.bodyBackgroundColor};

  display: none;
  align-items: center;
  justify-content: space-between;

  h3 {
    a {
      display: inline-block;
      &:hover {
        animation: 0.8s ${wobble} ease-out;
      }
    }
  }

  @media screen and (max-width: 680px) {
    flex-direction: column;
    gap: 20px;
  }

  z-index: 9999;
`

export const BannerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`
