import theme from "styled-theming"

const bodyBackgroundColor = theme("mode", {
  light: "#fff",
  dark: "#000",
})

const mainColor = theme("mode", {
  light: "#000",
  dark: "#fff",
})

const fonts = {
  primary: `"Principal Regular", "Helvetica Neue", Helvetica, Arial, -apple-system, sans-serif`,
  secondary: `"Principal Black", "Helvetica Neue", Helvetica, Arial, -apple-system, sans-serif`,
}

const colors = {
  dark: "#000",
  light: "#fff",
  grey: "#e8e8e8",
}

const margins = {
  page: "20px",
  minimal: "6px",
  base: "10px",
  medium: "20px",
  large: "40px",
}

const mobileMargins = {
  page: "12px",
}

const navHeight = 66

const mobileNavHeight = 60

const zIndexes = {
  navigation: 99999,
  fullscreenNavigation: 999999,
  loading: 9999999,
}

const fontSizes = {
  xxsmall: "12px",
  xsmall: "14px",
  small: "16px",
  base: "20px",
  medium: "34px",
  large: "86px",
}

const lineHeights = {
  base: "1.26",
  mobileBase: "1.3",
  large: "1.12",
  mobileLarge: "1.14",
}

export default {
  bodyBackgroundColor,
  mainColor,
  fonts,
  colors,
  margins,
  mobileMargins,
  navHeight,
  mobileNavHeight,
  zIndexes,
  fontSizes,
  lineHeights,
}
