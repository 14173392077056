import React, { useState, useEffect } from "react"
import { GlobalReset, GlobalStyle } from "../styles/global"
import SEO from "../components/seo/seo"
import Navigation from "../components/navigation/index"
import CookieBanner from "../components/cookie-banner"
import Loading from "../components/loading"

const LayoutDefault = props => {

  const [loaderIsActive, setLoaderIsActive] = useState(true)
  const [windowHeight, setWindowHeight] = useState(0)
  const consoleCredits = () => {
    console.log('%c Code: Christian Laufenböck', 'font-weight: bold; font-size: 12px; color: rgba(81,36,251);');
    console.log('%c Visit: www.laufenboeck.com', 'font-weight: bold; font-size: 12px; color: rgba(81,36,251);');
    console.log('%c Design: Studio Fabio Biesel', 'font-weight: bold; font-size: 12px; color: rgba(81,36,251);');
  }

  useEffect(() => {
    consoleCredits()
  })

  useEffect(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useEffect(() => {
    if (window.sessionStorage.getItem('loading-played')) {
      setLoaderIsActive(false)
      document.body.style.overflow = ''
      document.body.style.height = ''
    } else {
      setLoaderIsActive(true)
      document.body.style.overflow = 'hidden'
      document.body.style.height = `${window.innerHeight}px`
      setTimeout(() => {
        setLoaderIsActive(false)
        document.body.style.overflow = ''
        document.body.style.height = ''
        window.sessionStorage.setItem('loading-played', 'true')
      }, 6400)
    }
  }, [])

  return (
    <>
      <SEO />
      <GlobalReset />
      <GlobalStyle />
      <Loading loaderIsActive={ loaderIsActive } />
      <Navigation loaderIsActive={ loaderIsActive } />
      <CookieBanner />
      <main style={{ opacity: loaderIsActive ? 0 : 1 }}>
        {props.children}
      </main>
    </>
  )
}

export default LayoutDefault
