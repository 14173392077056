import { createGlobalStyle } from "styled-components"
import themeSettings from "../styles/theme"
import PrincipalBlackEot from "../fonts/Principal-Black.eot"
import PrincipalBlackWoff from "../fonts/Principal-Black.woff"
import PrincipalBlackWoff2 from "../fonts/Principal-Black.woff2"
import PrincipalRegularEot from "../fonts/Principal-Regular.eot"
import PrincipalRegularWoff from "../fonts/Principal-Regular.woff"
import PrincipalRegularWoff2 from "../fonts/Principal-Regular.woff2"

export const GlobalReset = createGlobalStyle`

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

`

export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Principal Regular";
    src: local("Principal Regular"), url("${PrincipalRegularEot}");
    src: url("${PrincipalRegularEot}?#iefix") format("embedded-opentype"),
        url("${PrincipalRegularWoff2}") format("woff2"),
        url("${PrincipalRegularWoff}") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Principal Black";
    src: local("Principal Black"), url("${PrincipalBlackEot}");
    src: url("${PrincipalBlackEot}?#iefix") format("embedded-opentype"),
        url("${PrincipalBlackWoff2}") format("woff2"),
        url("${PrincipalBlackWoff}") format("woff");
    font-weight: 900;
    font-style: normal;
  }

  *, html, body {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: "Principal Regular", "Helvetica Neue", Helvetica, Arial, -apple-system, sans-serif;
    font-weight: normal;
    font-size: ${themeSettings.fontSizes.base};
    line-height: ${themeSettings.lineHeights.base};
    background-color: ${themeSettings.bodyBackgroundColor};
    color: ${themeSettings.mainColor};

    @media screen and (max-width: 414px) {
      font-size: ${themeSettings.fontSizes.small};
      line-height: ${themeSettings.lineHeights.mobileBase};
    }
  }

  a {
    color: ${themeSettings.mainColor};
    text-decoration: none;
    transition: transform 0.5s ease;
  }

  ::selection {
    background: ${themeSettings.mainColor};
    color: ${themeSettings.bodyBackgroundColor};
    text-shadow: none;
  }

`