/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

  const SEO = () => {

    const { prismicSeo } = useStaticQuery(
      graphql`
        query {
          prismicSeo {
            data {
              website_title {
                text
              }
              website_description {
                text
              }
              short_description {
                text
              }
              social_sharing_image {
                alt
                large {
                  url
                }
              }
              twitter_sharing_image {
                url
              }
            }
          }
        }      
      `
    )

  return (
    <Helmet>

      <title>{prismicSeo.data.website_title.text}</title>

      <meta name="description" content={prismicSeo.data.website_description.text} />
      <meta itemprop="image" content={prismicSeo.data.social_sharing_image.url} />

      <meta
        property="og:url"
        content="https://www.smorgasbordstudio.com/"
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:title"
        content={prismicSeo.data.website_title.text}
      />
      <meta
        property="og:description"
        content={prismicSeo.data.website_description.text}
      />
      <meta
        property="og:image"
        content={prismicSeo.data.twitter_sharing_image.url}
      />

      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:title"
        content="Title"
      />
      <meta
        name="twitter:site"
        content="@Smorgasbord_"
      />
      <meta
        name="twitter:description"
        content={prismicSeo.data.website_description.text}
      />
      <meta
        name="twitter:image"
        content={prismicSeo.data.twitter_sharing_image.url}
      />
    </Helmet>
  )
}


export default SEO